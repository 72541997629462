<template>
  <div class="form-grid-item">
    <label v-bind:for="dataId">{{ fullName }}</label>
    <select v-bind:id="dataId" v-model="selected">
      <option disabled value=""></option>
      <option 
        v-for="opt in options"
        v-bind:key="opt"
        v-bind:value="opt"
        :disabled="!activated">
        {{ opt }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    activated: Boolean,
    fullName: String,
    dataId: String,
    options: Array
  },

  data: function() {
    return { selected: '' };
  },

  watch: {
    selected: function() {
      this.$emit('changed', this.dataId, this.selected);
    },

    activated: function() {
      if (!this.activated) { this.selected = ''; } 
    }
  }
}
</script>

<style scoped>
label {
  display:block;
}
</style>