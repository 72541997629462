<template>
  <div id="app">
    <div class="header">
      <h1>Cyber-Physical Synthetic Electric Distribution System Network</h1>
    </div>

    <div class="row">
      <div class="two-column">
        <div>
          <img src="UI_Assist-logo.png" alt="UI Assist logo" />
        </div>
        <div>
          <h2>Acknowledgement</h2>
          <p>This material is based upon work supported by the U.S. Department of Energy under Award Number DE-IA0000025.  Neither the United States Government nor any agency thereof, nor any of their employees, makes any warranty, express or implied, or assumes any legal liability or responsibility for the accuracy, completeness, or usefulness of any information, apparatus, product, or process disclosed, or represents that its use would not infringe privately owned rights. The views and opinions of authors expressed herein do not necessarily state or reflect those of the United States Government or any agency thereof.</p>
        </div>
      </div>
    </div>

    <div class="row">
      <h2>Instructions</h2>
      <p>This application is used to distribute cyber-physical models of power systems. Use the below drop-down menus to select the attributes for your model. This should be done by first selecting a network type from the top leftmost dropdown menu (Rural, Urban, Radial, Mesh/Ring, Synthetic, or Cyber-Physical), and then selecting an option from the corresponding options menu if it is available (e.g. Synthetic Options if Synthetic is selected). For the Radial network type, an additional Secondary Extension option should be selected. A preview of the graph topology will then display below if it is available. This may take a few seconds if the graph is particularly large. When you have confirmed that this is the graph that you want, click "Get Model" to receive a zip file containing documentation for the model, along with .dss scripts that can be used in power simulations.</p>
    </div>

    <div class="row">
      <dynamic-form v-on:getModel="getModel" v-on:getGraph="getGraph"/>
    </div>

    <div class="row">
      <graph-view :graph-name="graphName"/>
    </div>
  </div>
</template>

<script>
import DynamicForm from './components/DynamicForm.vue';
import GraphView from './components/GraphView.vue';

// Get name of a models with radial type
function radialName(form) {
  const trailing = { "5 Node": "+5", "10 Node": "+10", "None": "" };
  return form.radialOpts + trailing[form.secondaryExt];
}

// Gets the name of a model for the /graph and /dss routes
function canonicalName(form) {
  switch (form.netType) {
  case 'Synthetic':
    return form.synthOpts;
  case 'Radial':
    return radialName(form);
  case 'Cyber-Physical':
    return 'Cyber ' + form.cyberOpts;
  default:
    return form.netType;
  }
}

// Downloads a model by creating a dummy link and clicking it.
function downloadDSS(modelName) {
  const formdata = {
    'model-name' : modelName
  };

  const dlURL = '/dss?' + new URLSearchParams(formdata);
  let element = document.createElement('a');
  element.setAttribute('href', dlURL);
  element.setAttribute('download', 'model.zip');

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default {
  name: 'App',

  data: function() {
    return { graphName: '' };
  },

  methods: {
    getGraph: function(form) {
      this.graphName = canonicalName(form);
    },

    getModel: function(form) {
      downloadDSS(this.graphName);
    }
  },

  components: {
    'DynamicForm': DynamicForm,
    'GraphView': GraphView
  }
}
</script>

<style>
* {
  box-sizing: border-box;
}
</style>

<style scoped>
.header {
  overflow: hidden;
  background-color: black;
}

.header h1 {
  float: left;
  color: white;
  display: block;
  padding-left: 10px;
  font-size: 1.5em;
}

h2 {
  font-size: 1.0em;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.two-column {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto;
}
</style>