<template>
  <div>
    <h2>Graph View</h2>
    <img v-if="blobURL != ''" :src="blobURL" :alt="altText"/>
  </div>
</template>

<script>
import Viz from 'viz.js';
import workerURL from 'file-loader!viz.js/lite.render.js';

export default {
  props: { graphName: String },

  data: function() {
    return { blobURL: '', altText: '' };
  },

  methods: {
    setImg: function(img) {
      this.blobURL = img.src;
    },

    setAlt: function(text) {
      this.altText = text;
    }
  },

  watch: {
    graphName: function() {
      // Remove graph if empty
      if (this.graphName === '') {
        this.blobURL = '';
        return;
      }

      // Otherwise, fetch graph and render an SVG element
      let viz = new Viz({ workerURL });
      const formdata = { 'graph-name' : this.graphName };

      fetch('/graph?' + new URLSearchParams(formdata))
      .then(data => data.text())
      .then(text => {
        if (text !== 'Unrecognized distribution system') {
          viz.renderImageElement(text).then(this.setImg);
          if (text.includes("No graph visualization")) {
            this.setAlt("No graph visualization available for this model");
          }
          else {
            this.setAlt("A visualization of a graph")
          }
        }
      });
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
  max-height: 100%;
}

h2 {
  font-size: 1.0em;
}
</style>