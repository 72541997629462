<template>
  <div class="form-grid">
    <dynamic-dropdown
      data-id="netType"
      full-name="Network Type"
      :options="['Rural', 'Urban', 'Radial', 'Mesh/Ring', 'Synthetic', 'Cyber-Physical']"
      :activated="display.netType"
      v-on:changed="updateId"/>

    <dynamic-dropdown
      data-id="synthOpts"
      full-name="Synthetic Options"
      :options="['Primary', 'Secondary', 'Primary+Secondary']"
      :activated="display.synthOpts"
      v-on:changed="updateId"/>

    <dynamic-dropdown
      data-id="radialOpts"
      full-name="Radial Options"
      :options="['13 bus', '34 bus', '37 bus', '123 bus']"
      :activated="display.radialOpts"
      v-on:changed="updateId"/>

    <dynamic-dropdown
      data-id="secondaryExt"
      full-name="Secondary Extensions"
      :options="['None', '5 Node', '10 Node']"
      :activated="display.radialOpts"
      v-on:changed="updateId"/>

    <dynamic-dropdown
      data-id="cyberOpts"
      full-name="Cyber-Physical Options"
      :options="['13 Node', '34 Node']"
      :activated="display.cyberOpts"
      v-on:changed="updateId"/>

    <div class="form-grid-item">
      <br />
      <button v-on:click="getModel">Get Model</button>
    </div>
  </div>
</template>

<script>
import DynamicDropdown from './DynamicDropdown.vue'

// Generates a new model for which dropdowns should be accessible.
function updateModel(oldModel) {
  let formData = Object.assign({}, oldModel.formData);

  const dispSynthOpts  = formData.netType === 'Synthetic';
  const dispRadialOpts = formData.netType === 'Radial';
  const dispCyberOpts  = formData.netType === 'Cyber-Physical';

  let display = {
    netType: true,
    synthOpts: dispSynthOpts,
    radialOpts: dispRadialOpts,
    cyberOpts: dispCyberOpts
  };

  // If a dropdown has no options selected, we shouldn't keep any residual
  // values in the formData.
  for (const id in display) {
    if (!display[id]) { 
      formData[id] = '';
    }
  }

  return {
    formData: formData,
    display: display,
  };
}

export default {
  data: function() {
    // Represents the selected values of the dropdowns.
    let formData = {
      netType: '',
      synthOpts: '',
      radialOpts: '',
      secondaryExt: '',
      cyberOpts: ''
    };

    // Represents which dropdowns should have any values selected.
    let display = {
      netType: true,
      synthOpts: false,
      radialOpts: false,
      cyberOpts: false,
    };

    return {
      formData: formData,
      display: display,
    };
  },

  methods: {
    // Update the model based on a change in dropdowns.
    // This should also fetch a new graph if possible.
    updateId: function(id, value) {
      this.formData[id] = value;

      let nextModel = updateModel(this.$data);
      this.display  = nextModel.display;
      this.formData = nextModel.formData;

      this.$emit('getGraph', this.formData);
    },

    // Propogate a button press to the main application.
    // This should download a DSS file if it is available.
    getModel: function() {
      this.$emit('getModel', this.formData);
    }
  },

  components: {
    'DynamicDropdown': DynamicDropdown
  }
}
</script>

<style scoped>
.form-grid {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: auto auto auto auto;
}

.form-grid-item {
  padding: 20px;
}
</style>